import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import i18n from '@/i18n';
import '@/sass/overrides.sass';

Vue.use(Vuetify)

const theme = {
  primary: '#E91E63',
  secondary: '#9C27b0',
  accent: '#9C27b0',
  info: '#00CAE3',
}

const theme2 = {
  primary: '#3B5998',
  secondary: '#821C02',
  accent: '#542437',
  info: '#A8C6D7',
}

export default new Vuetify({
  lang: {
    t: (key: any, ...params: any) => i18n.t(key, params),
  },
  theme: {
    themes: {
      dark: theme,
      light: theme2,
    },
  },
})
