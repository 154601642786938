import axios from 'axios';
import { baseApiUrl, baseApiUrl2 } from '@/global';

const actions = {
  loadAccounts (context: any) {
    axios.defaults.headers.common['Content-Type'] = 'application/json';
    axios.get(`${baseApiUrl2}/contas`)
    .then(response => {
      context.commit('setAccounts', response.data.data);
    })
  },
  editAccount (context: any) {
    axios.defaults.headers.common['Content-Type'] = 'application/json';
    axios.get(`${baseApiUrl2}/contas`)
    .then(response => {
      context.commit('setAccounts', response.data.data);
    })
  },
  loadUsers (context: any) {
    axios.defaults.headers.common['Content-Type'] = 'application/json';
    axios.get(`${baseApiUrl2}/usuarios`)
    .then(response => {
      context.commit('setUsers', response.data.data);
    })
  },
  loadProducts (context: any) {
    axios.defaults.headers.common['Content-Type'] = 'application/json';
    axios.get(`${baseApiUrl2}/produtos`)
    .then(response => {
      context.commit('setProducts', response.data.data);
    })
    .catch(e => console.error(e))
  },
  loadClients (context: any) {
    axios.defaults.headers.common['Content-Type'] = 'application/json';
    axios.get(`${baseApiUrl}clients`)
    .then(response => {
      context.commit('setClients', response.data.data);
    })
  },
  loadSuppliers (context: any) {
    axios.defaults.headers.common['Content-Type'] = 'application/json';
    axios.get(`${baseApiUrl2}/fornecedores`)
    .then(response => {
      context.commit('setSuppliers', response.data.data);
    })
  },
  loadCarriers (context: any) {
    axios.defaults.headers.common['Content-Type'] = 'application/json';
    axios.get(`${baseApiUrl2}/transportadoras`)
    .then(response => {
      context.commit('setCarriers', response.data.data);
    })
  },
  async loadOrders (context: any) {
    axios.defaults.headers.common['Content-Type'] = 'application/json';
    await axios.get(`${baseApiUrl2}/pedidos`)
    .then(response => {
      context.commit('setOrders', response.data.data);
    })
  },
}

export default actions;