// =========================================================
// * Vuetify Material Dashboard PRO - v2.1.0
// =========================================================
//
// * Product Page: https://www.creative-tim.com/product/vuetify-material-dashboard-pro
// * Copyright 2019 Creative Tim (https://www.creative-tim.com)
//
// * Coded by Creative Tim
//
// =========================================================
//
// * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import './plugins/base';
import './plugins/chartist';
import './plugins/vee-validate';
import './plugins/vue-world-map';
import vuetify from './plugins/vuetify';
import i18n from './i18n';
import { VueMaskDirective } from 'v-mask';

import './assets/css/visiolens/utilities.css';
import './assets/css/visiolens/swalExtras.css';

//Fontawesome icons	
import "@fortawesome/fontawesome-free/css/all.css";

// VeeValidate
import { ValidationProvider, ValidationObserver } from "vee-validate";
Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);
import { extend } from "vee-validate";
import { required, email, min, confirmed, max, min_value as minValue } from "vee-validate/dist/rules";
extend("email", email);
extend("required", required);
extend("min", min);
extend("min_value", minValue);
extend("confirmed", confirmed);
extend("max", max);

Vue.directive('mask', VueMaskDirective)
Vue.config.productionTip = false
Vue.config.devtools = true

import axios from 'axios';
import axios2 from "./axios2";
Vue.prototype.$http = axios2;

axios.interceptors.response.use(function(response) {
  return response;
}, function(err) {
  if (err && err.response && err.response !== 200) {
    var errorMessage = '';
    if (err.response.data && err.response.data.data) {
      var cont = 1;
      err.response.data.data.validationDetail.forEach((vd: any) => {
        if (errorMessage.length > 0) {
          errorMessage += "\n";
        }
        errorMessage += `${cont} - ${vd.message}`;
        cont++;
      });
    }
    if (errorMessage.length === 0){
      errorMessage = "Ocorreu um erro.";
    }

    alert(errorMessage);

    return err;
  }
});

new Vue({
  router,
  store,
  vuetify: vuetify as any,
  i18n,
  components: {
    app: App,
  },
  render: h => h(App),
} as any).$mount('#app')
