import { Model } from "../Model";

export class AccountDetail extends Model {

  constructor(id: number, attributes: any) {
    super(id);
    this.id_accounts = attributes.id_accounts;
    this.cnpj = attributes.cnpj;
    this.ie = attributes.ie;
    this.company_name = attributes.company_name;
    this.razao_social = attributes.razao_social;
    this.name_rep = attributes.name_rep;
    this.cpf_rep = attributes.cpf_rep;
    this.email_rep = attributes.email_rep;
    this.email_adm = attributes.email_adm;
    this.phone_number1 = attributes.phone_number1;
    this.phone_number2 = attributes.phone_number2;
    this.address = attributes.address;
    this.district = attributes.district;
    this.city = attributes.city;
    this.state = attributes.state;
    this.country = attributes.country;
    this.logo = attributes.logo;
    this.layout = attributes.layout;
    if (attributes && attributes.payments_type) {
      this.payments_type = JSON.parse(attributes.payments_type);
    }
  }

  id_accounts: number;
  cnpj: string;
  ie: string;
  company_name: string;
  razao_social: string;
  name_rep: string;
  cpf_rep: string;
  email_rep: string;
  email_adm: string;
  phone_number1: string;
  phone_number2: string;
  address: string;
  district: string;
  city: string;
  state: string;
  country: string;
  logo: string;
  layout: string;
  payments_type: any;
}
