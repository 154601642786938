import axios from 'axios';

const mutations = {
  SET_BAR_IMAGE (state: any, payload: any) {
    state.barImage = payload;
  },
  SET_DRAWER (state: any, payload: any) {
    state.drawer = payload;
  },
  SET_SCRIM (state: any, payload: any) {
    state.barColor = payload;
  },
  setAccounts (state: any, data: any) {
    state.accounts = data
    //state.accounts.details = data.detail
  },
  setUsers (state: any, data: any) {
    state.users = data;
  },
  setOrders (state: any, data: any) {
    state.orders = data;
  },
  setProducts (state: any, data: any) {
    state.products = data;
  },
  setClients (state: any, data: any) {
    state.clients = data;
  },
  setSuppliers (state: any, data: any) {
    state.suppliers = data;
  },
  setCarriers (state: any, data: any) {
    state.carriers = data;
  },
  setAuthenticated (state: any, data: any) {
    state.authenticated = data;
  },
  setLoggedUser (state: any, data: any) {
    state.loggedUser = data;
  },
  setToken (state: any, data: any) {
    state.token = data;
    if (data) {
      axios.defaults.headers.common.Authorization = `Bearer ${state.token}`;
    } else {
      delete axios.defaults.headers.common.Authorization;
    }
  }
}

export default mutations;