import Vue from "vue";
import VueRouter, { NavigationGuardNext, Route, RouteConfig } from "vue-router";
import { permissionsRules } from '@/code/util/constants';
import localStorageUtil from "@/code/util/localStorageUtil";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '',
    component: () => import('@/views/base/DashboardIndex.vue'),
    children: [
      {
        path: '/',
        name: 'home',
        redirect: {
          name: 'Dashboard',
        },
      },
      {
        path: '/pages',
        name: 'pages',
        redirect: {
          name: 'Dashboard',
        },
      },
      {
        name: 'Dashboard',
        path: '/pages/home',
        component: () => import('@/views/dashboard/Dashboard.vue'),
      },
      {
        path: '/pages/dashboard-pedidos',
        name: 'dashboard-pedidos',
        component: () => import('@/views/dashboard/Pedidos.vue'),
      },
      {
        name: 'Grupos de contas',
        path: '/pages/accountGroups',
        component: () => import('@/views/account/AccountGroups.vue'),
        meta: {
          permission: permissionsRules.Admin
        }
      },
      {
        name: 'Contas',
        path: '/pages/accounts',
        component: () => import('@/views/account/Accounts.vue'),
        meta: {
          permission: permissionsRules.ManageGroup
        }
      },
      {
        name: 'Usuarios',
        path: '/pages/users',
        component: () => import('@/views/account/Users.vue'),
        meta: {
          permission: permissionsRules.ManageAccount
        }
      },
      {
        name: 'tables',
        path: '/pages/tables',
        component: () => import('@/views/account/Tables.vue'),
        meta: {
          title: 'Tabelas'
        }
      },
      {
        name: 'tablesList',
        path: '/pages/tables-list',
        component: () => import('@/views/account/ListTables.vue'),
        meta: {
          title: 'Lista de tabelas'
        }
      },
      {
        name: 'assistentAction',
        path: '/pages/assistent',
        component: () => import('@/views/assistente/AssistenteSelecaoAcao.vue'),
        meta: {
          title: 'Assistente',
          permission: permissionsRules.ManageGroup
        }
      },
      {
        name: 'HomeAssistente',
        path: '/pages/assistent/test',
        component: () => import('@/views/assistente/Home.vue'),
        children: [
          {
            name: 'assistentNewAccount',
            path: '/pages/assistent/test/new',
            component: () => import('@/views/assistente/Account.vue'),
            meta: {
              title: 'Nova loja',
              permission: permissionsRules.ManageGroup
            }
          },
          {
            name: 'assistentEditAccount',
            path: '/pages/assistent/test/review/:id',
            component: () => import('@/views/assistente/Account.vue'),
            meta: {
              title: 'Revisar loja',
              permission: permissionsRules.ManageGroup
            }
          },
          {
            name: 'assistentTablets',
            path: '/pages/assistent/test/:id/tablets',
            component: () => import('@/views/assistente/Tablets.vue'),
            meta: {
              title: 'Tablets',
              permission: permissionsRules.ManageGroup
            }
          },
          {
            name: 'assistentLinxData',
            path: '/pages/assistent/test/:id/linx-data',
            component: () => import('@/views/assistente/LinxData.vue'),
            meta: {
              title: 'Dados Linx',
              permission: permissionsRules.ManageGroup
            }
          },
          {
            name: 'assistentConfigs',
            path: '/pages/assistent/test/:id/configs',
            component: () => import('@/views/assistente/Configs.vue'),
            meta: {
              title: 'Configurações',
              permission: permissionsRules.ManageGroup
            }
          },
          {
            name: 'assistentPriceTables',
            path: '/pages/assistent/test/:id/price-tables',
            component: () => import('@/views/assistente/PriceTables.vue'),
            meta: {
              title: 'Tabelas de preço',
              permission: permissionsRules.ManageGroup
            }
          },
          {
            name: 'assistentPaymentMethods',
            path: '/pages/assistent/test/:id/payment-methods',
            component: () => import('@/views/assistente/PaymentMethods.vue'),
            meta: {
              title: 'Formas de pagamento',
              permission: permissionsRules.ManageGroup
            }
          },
          {
            name: 'assistentLayout',
            path: '/pages/assistent/test/:id/layout',
            component: () => import('@/views/assistente/Layout.vue'),
            meta: {
              title: 'Layout',
              permission: permissionsRules.ManageGroup
            }
          },
          {
            name: 'assistentLabs',
            path: '/pages/assistent/test/:id/labs',
            component: () => import('@/views/assistente/Labs.vue'),
            meta: {
              title: 'Laboratórios',
              permission: permissionsRules.ManageGroup
            }
          },
          {
            name: 'assistentProducts',
            path: '/pages/assistent/test/products/:id',
            component: () => import('@/views/assistente/Products.vue'),
            meta: {
              title: 'Produtos',
              permission: permissionsRules.ManageGroup
            }
          },
          {
            name: 'assistentReviewData',
            path: '/pages/assistent/test/:id/review-data',
            component: () => import('@/views/assistente/ReviewData.vue'),
            meta: {
              title: 'Revisão',
              permission: permissionsRules.ManageGroup
            }
          },
        ]
      },
    ]
  },
  {
    path: '',
    component: () => import('@/views/base/PagesIndex.vue'),
    children: [
      {
        name: 'Login',
        path: '/login',
        component: () => import('@/views/authorization/Login.vue'),
        meta: {
          guest: true
        }
      },
      {
        name: 'Logout',
        path: '/logout',
        component: () => import('@/views/authorization/Logout.vue'),
        meta: {
          guest: true
        }
      },
      {
        name: 'Unauthorized',
        path: '/unauthorized',
        component: () => import('@/views/authorization/Unauthorized.vue'),
        meta: {
          guest: true
        }
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to: Route, from: Route, next: NavigationGuardNext<Vue>) => {
    //const token = store.state.token;
    const isAuth = localStorageUtil.isAuth();
    if (to.meta?.guest) {
      return next();
    }
    else if (isAuth) {

      //verificar a permissão do usuario aqui
      if (to.meta && to.meta.permission) {
        var user = localStorageUtil.getCurrentUser();
        if (to.meta.permission.some((p) => {
          return p == user?.role_enum;
        })) {
          return next();
        } else {
          //Criar uma página de não autorizado
          router.push({ name: 'Unauthorized' });
        }
      } else {
        return next();
      }

      //if (!to.meta || !to.meta.permission || to.meta.permission.some((p) => p == userRule)) {

      //}
    }
    else
    {
      router.push({ name: "Login", query: { nextUrl: to.path } })
    }
  });

export default router;
