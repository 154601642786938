import { EUserRole } from '@/code/enums/EUserRole';

const countries = {
  Brasil: { id: "BR", name: "Brasil" }
}

const states = [
  { id: 'AC', name: 'Acre', country: countries.Brasil },
  { id: 'AL', name: 'Alagoas', country: countries.Brasil },
  { id: 'AP', name: 'Amapá', country: countries.Brasil },
  { id: 'AM', name: 'Amazonas', country: countries.Brasil },
  { id: 'BA', name: 'Bahia', country: countries.Brasil },
  { id: 'CE', name: 'Ceará', country: countries.Brasil },
  { id: 'DF', name: 'Distrito Federal', country: countries.Brasil },
  { id: 'ES', name: 'Espírito Santo', country: countries.Brasil },
  { id: 'GO', name: 'Goiás', country: countries.Brasil },
  { id: 'MA', name: 'Maranhão', country: countries.Brasil },
  { id: 'MT', name: 'Mato Grosso', country: countries.Brasil },
  { id: 'MS', name: 'Mato Grosso do Sul', country: countries.Brasil },
  { id: 'MG', name: 'Minas Gerais', country: countries.Brasil },
  { id: 'PA', name: 'Pará', country: countries.Brasil },
  { id: 'PB', name: 'Paraíba ', country: countries.Brasil },
  { id: 'PR', name: 'Paraná', country: countries.Brasil },
  { id: 'PE', name: 'Pernambuco', country: countries.Brasil },
  { id: 'PI', name: 'Piauí', country: countries.Brasil },
  { id: 'RJ', name: 'Rio de Janeiro', country: countries.Brasil },
  { id: 'RN', name: 'Rio Grande do Norte', country: countries.Brasil },
  { id: 'RS', name: 'Rio Grande do Sul ', country: countries.Brasil },
  { id: 'RO', name: 'Rondônia', country: countries.Brasil },
  { id: 'RR', name: 'Roraima', country: countries.Brasil },
  { id: 'SC', name: 'Santa Catarina ', country: countries.Brasil },
  { id: 'SP', name: 'São Paulo ', country: countries.Brasil },
  { id: 'SE', name: 'Sergipe', country: countries.Brasil },
  { id: 'TO', name: 'Tocantins', country: countries.Brasil },
];

const roles = [
  {id: 1, name: "Vendedor", role_enum: EUserRole.Seller },
  {id: 2, name: "Gestor de conta", role_enum: EUserRole.ManageAccount },
  {id: 3, name: "Gestor de grupo", role_enum: EUserRole.ManageGroup },
  {id: 1000, name: "System Admin", role_enum: EUserRole.Admin },
];

const apiAddress = 'https://641ysleggl.execute-api.us-east-2.amazonaws.com/dev/';

const permissionsRules = {
  Admin: [ EUserRole.Admin ],
  ManageGroup: [ EUserRole.Admin, EUserRole.ManageGroup ],
  ManageAccount: [ EUserRole.Admin, EUserRole.ManageGroup, EUserRole.ManageAccount ],
  Seller: [ EUserRole.Admin, EUserRole.ManageGroup, EUserRole.ManageAccount, EUserRole.Seller ]
};

const authConsts = {
  storeToken: 'ACCESS_TOKEN',
  storeIdUser: 'ID_USER',
  storeIsAuth: 'IS_AUTH',
  storeUserInfo: 'USER_INFO',
};

const assistentSteps = [
  { title: 'Cadastro', routeName: 'assistentEditAccount', step: 1, icon: "fas fa-home" },
  { title: 'Tablets', routeName: 'assistentTablets', step: 2, icon: "fas fa-tablet-alt" },
  { title: 'Dados ERP', routeName: 'assistentLinxData', step: 3, icon: "fas fa-link" },
  { title: 'Tabelas de preço', routeName: 'assistentPriceTables', step: 4, icon: "fas fa-tag" },
  { title: 'Forma de pagamento', routeName: 'assistentPaymentMethods', step: 5, icon: "far fa-credit-card" },
  { title: 'Config', routeName: 'assistentConfigs', step: 6, icon: "fas fa-tags" },
  { title: 'Layout', routeName: 'assistentLayout', step: 7, icon: "far fa-file-alt" },
  // { title: 'Laboratórios', routeName: 'assistentLabs', step: 8, icon: "fas fa-flask" },
  { title: 'Produtos', routeName: 'assistentProducts', step: 9, icon: "fas fa-sync-alt" },
  { title: 'Revisão', routeName: 'assistentReviewData', step: 10, icon: "fas fa-tasks" },
];

export { states, roles, apiAddress, permissionsRules, authConsts, assistentSteps }
