import { Model } from "../Model";
import { AccountDetail } from "./AccountDetail";

export class Account extends Model {

  constructor(id: number, attributes: any) {
    super(id);
    this.account = attributes.account;
    this.license = attributes.license;
    this.feature_id = attributes.feature_id;
    this.active = attributes.active;
    this.parent_id = parseInt(attributes.parent_id);
    this.linx_portal = attributes.linx_portal;
    this.linx_sandbox = attributes.linx_sandbox;
    this.linx_cnpj = attributes.linx_cnpj;
    this.linx_empresaid = attributes.linx_empresaid;
    this.linx_tabela_preco_id = attributes.linx_tabela_preco_id;
    this.printnode_id = attributes.printnode_id;
    this.expire_date = attributes.expire_date;
    this.created = attributes.created;
    this.modified = attributes.modified;
    this.type = attributes.type;
    this.erp = attributes.erp;
    this.sync_error = attributes.sync_error;
    this.meta_labels = attributes.meta_labels;
    this.meta_configs = attributes.meta_configs;

    this.detail = new AccountDetail(0, {});
    if (attributes.detail) {
      this.detail = new AccountDetail(attributes.detail.id, attributes.detail);
    }
  }

  account: string;
  license: string;
  feature_id: number;
  active: Boolean;
  parent_id: number;
  linx_portal: string;
  linx_sandbox: boolean;
  linx_cnpj: string;
  linx_empresaid: string;
  linx_tabela_preco_id: string;
  printnode_id: string;
  expire_date: Date;
  created: Date;
  modified: Date;
  type: string;
  erp: string;
  detail: AccountDetail;
  sync_error: string;
  meta_configs?: any;
  meta_labels?: any;
}
