import { authConsts } from "./constants";
import { User } from '@/code/models/User';
import { EventBus } from '@/code/util/eventBus';
import { UserGroup } from "@/code/models/User/UserGroup";
import { Account } from "@/code/models/User/Account";

export default {

  login: function(token: string/*, idUser: number*/) : void {
    if (token) {
      localStorage.setItem(authConsts.storeToken, token);
      // localStorage.setItem(authConsts.storeIdUser, idUser.toString());
      localStorage.setItem(authConsts.storeIsAuth, "true");
    } else {
      this.logout();
    }
  },

  logout: () : void => {
    localStorage.removeItem(authConsts.storeIsAuth);
    localStorage.removeItem(authConsts.storeToken);
    localStorage.removeItem(authConsts.storeUserInfo);
  },

  getToken: () : string | null => {
    return localStorage.getItem(authConsts.storeToken);
  },

  isAuth: () => {
    const isAuth = localStorage.getItem(authConsts.storeIsAuth);
    return isAuth === "true";
  },

  saveCurrentUser: function (user: any) {
    this.saveCurrentUserJSON(JSON.stringify(user));
  },
  saveCurrentUserJSON: function (json: string) {
    localStorage.setItem(authConsts.storeUserInfo, json);
    EventBus.$emit("currentUserLoaded");
  },

  getCurrentUser: () : User | null => {
    try {
      const user = localStorage.getItem(authConsts.storeUserInfo);
      if (user !== null) {
        const u = JSON.parse(user);
        return u;
      } else {
        return null;
      }
    } catch {
      return null;
    }
  },

  getCurrentUserGroup: () : UserGroup | null => {
    const user = localStorage.getItem(authConsts.storeUserInfo);
    if (user) {
      const u = JSON.parse(user);
      if (u.group) {
        return new UserGroup(u.group.id, u.group);
      }
    }
    return null;
  },

  getCurrentUserAccount: () : Account | null => {
    const user = localStorage.getItem(authConsts.storeUserInfo);
    if (user) {
      const u = JSON.parse(user);
      if (u.group) {
        return new Account(u.account.id, u.group);
      }
    }
    return null;
  }

}