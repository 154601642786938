<template>
  <router-view
    @authenticated="setAuthenticated"
  />
</template>

<script>
  // import Vue from 'vue'
  import { userKey } from '@/global'
  export default {
    name: 'App',
    data () {
      return {
        authenticated: true,
      }
    },
    mounted () {
      // window.__VUE_DEVTOOLS_GLOBAL_HOOK__.Vue = Vue.constructor
      this.validateToken()

      /*
      if (process.env.NODE_ENV !== 'production') {
        console.log('authenticated', this.authenticated)
      }
      if (!this.authenticated) {
        this.$router.replace({ name: 'Login' })
      } */
    },
    methods: {
      validateToken () {
        // debugger;
        // this.authenticated = true
        // const token = localStorage.getItem(userKey)
        // if (token) {
        //   this.$store.commit('setToken', token)
        // }
        // if (!token) {
        //   this.authenticated = false
        //   return this.$router.push({ name: 'Login' })
        // }
      },
      setAuthenticated (status) {
        this.authenticated = status
      },
      logout () {
        this.authenticated = false
      },
    },
  }
</script>
