const state = {
  authenticated: true,
  barColor: 'rgba(0, 0, 0, .8), rgba(0, 0, 0, .8)',
  //barImage: 'https://demos.creative-tim.com/material-dashboard-pro/assets/img/sidebar-1.jpg',
  barImage: 'https://www.google.com/url?sa=i&url=https%3A%2F%2Fwww.unimedpenapolis.com.br%2FImagensGeral%2FForms%2FDispForm.aspx%3FID%3D4&psig=AOvVaw3rvwEQm28kmuvZbz791mJf&ust=1641648681937000&source=images&cd=vfe&ved=0CAgQjRxqFwoTCODawofgn_UCFQAAAAAdAAAAABAD',
  drawer: null,
  loggedUser: null,
  token: null,
  users: [
    {
      id: '',
      id_accounts: '',
      id_role: '',
      login: '',
      email: '',
      name: '',
      created: '',
      modified: '',
      address: '',
      city: '',
      country: '',
      state: '',
      cpf: '',
      phone: '',
      zipcode: '',
      role: '',
      permissions: '',
      role_description: '',
      account: '',
    },
  ],
  accounts: [
    {
      id: '',
      license: '',
      account: '',
      details: {
        id_details: '',
        cnpj: '',
        ie: '',
        company_name: '',
        razao_social: '',
        name_rep: '',
        cpf_rep: '',
        email_rep: '',
        email_adm: '',
        phone_number1: '',
        phone_number2: '',
        address: '',
        district: '',
        state: '',
        country: '',
        created: '',
      },
    },
  ],
  clients: [],
  orders: [],
  products: [],
  suppliers: [],
  carriers: [],
}

export default state;