import axios, { AxiosRequestConfig } from "axios";
import { apiAddress } from '@/code/util/constants';
import { UnauthorizedException } from "@/code/exceptions/UnauthorizedException";
import store from '@/store';
import localStorageUtil from "@/code/util/localStorageUtil";
import Swal from "sweetalert2";
import router from "@/router";
import { AxiosRequestConfigExtended } from "@/code/interfaces/AxiosRequestConfigExtended";

const ax = axios.create({
  baseURL: apiAddress,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  }
});

ax.interceptors.request.use(
  (request) => {
    // if (!urlNotAuthorize.some(v => v === getUrlWithoutQuery(request.url))) {
    if (!request.url?.includes('auth/login')) {
      const token = localStorageUtil.getToken();
      if (token) {
        request.headers.Authorization = `Bearer ${ token }`;
      } else {
        throw new UnauthorizedException();
      }
    }

    return request;
  }
);


ax.interceptors.response.use(function(response) {
  return response;
}, function(err) {
  if (err && err.response && (err.response.status < 200 || err.response.status > 299)) {

    if (err.config.url.startsWith('auth/login')) {
      return err;
    }

    var errorMessage = '';
    if (err.response.status === 401) {
        router.push({ name: 'Login' })
    } else if (err.response.status === 403) {
      errorMessage = 'Usuário não possuí permissão para esta operação!';
      router.push({ name: 'Unauthorized' });
    } else {
      const myConfig: AxiosRequestConfigExtended = err.config;
      if (myConfig && myConfig.handleError === false) {
        return Promise.reject(err);
      }

      if (err.response.data) {
        if (err.response.data.data) {
          var cont = 1;
          err.response.data.data.validationDetail.forEach((vd: any) => {
            if (errorMessage.length > 0) {
              errorMessage += "<br />";
            }
            errorMessage += `${cont} - ${vd.message}`;
            cont++;
          });
        } else if (err.response.data.message) {
          errorMessage = err.response.data.message;
        } else {
          errorMessage = err.message ? err.message : 'Ocorreu um erro desconhecido na requisição';
        }
      } else {
        errorMessage = err.message ? err.message : 'Ocorreu um erro desconhecido na requisição';
      }

      if (errorMessage.length === 0) {
        if (err.response.data.message.length > 0) {
          errorMessage = err.response.data.message;
        } else {
          errorMessage = "Erro desconhecido.";
        }
      }

      Swal.fire({
        title: 'Ocorreu um erro!',
        html: errorMessage,
        icon: 'error'
      });

      return err;
    }
  }
});


export default ax;