const getters = {
  accounts (state: any) {
    return state.accounts;
  },
  users (state: any) {
    return state.users;
  },
  orders (state: any) {
    return state.orders;
  },
  products (state: any) {
    return state.products;
  },
  clients (state: any) {
    return state.clients;
  },
  suppliers (state: any) {
    return state.suppliers;
  },
  carriers (state: any) {
    return state.carriers;
  },
  authenticated (state: any) {
    return state.authenticated;
  },
  loggedUser (state: any) {
    return state.loggedUser;
  },
  token (state: any) {
    return state.token;
  }
}

export default getters;