import { Model } from "../Model";

export class UserGroup extends Model {

  constructor(id: number, attributes: any) {
    super(id);
    this.account = attributes.account;
  }

  account: string;
}